import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../seo";

// Static query can be used anywhere but doesn't accept parameters
// can't use context,

// Page must be used in Pages

export default class postLayout extends Component {
  render() {
    const { markdownRemark } = this.props.data;
    return (
      <Layout>
        <SEO title="FAQ | Alfredo Riveroll" />
        <div className="wrapper ComponentSeparation">
          <h1
            className="t2"
            style={{
              color: "#3BD788"
            }}
          >
            {markdownRemark.frontmatter.title}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: markdownRemark.html
            }}
          />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
